import axios from 'axios'
const map = {
  namespaced: true,
  state: {
    token: '',
    userId: '',
    isSearched: 0,
    salesPoints: [],
    tmpSalesPoints: [],
    
  },
  getters: {
    // ...
    GET_SALESPOINTS: (state) => {
      return state.salesPoints
    }
  },
  mutations: {
    SET_TOKEN(state){
        let token = localStorage.getItem("userToken")
        state.token = token
    },
    SET_SALESPOINTS(state,data){
        state.salesPoints = data
    },

    SEARCH_SALESPOINT(state, data){
      if(state.isSearched == 0){
        state.tmpSalesPoints = state.salesPoints
        state.isSearched = 1
      }
      var items = state.tmpSalesPoints.filter(item =>{
        if(item.address == null){item.address =  'no address'}
        if(item.name.toLowerCase().match(data.toLowerCase()) || item.address.toLowerCase().match(data.toLowerCase())){
            return item;
        }
      })
      state.salesPoints = items
    }
    
  },
  actions: {
    async ACT_SALESPOINTS_LIST({commit}, data){
       let response = await axios.get(`/sale-points/near-by?location_in=${data.lat},${data.lang},${data.radius}`)
       if(response.data.success) commit('SET_SALESPOINTS', response.data.date)
    },
    async ACT_SALESPOINTS({commit}){
       let response = await axios.get(`/sale-points/near-by`)
       if(response.data.success) commit('SET_SALESPOINTS', response.data.date)
    },

       
    

  },
  modules: {
  }


}

export default map