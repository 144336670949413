import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);
const routes = new Router({
  mode: "history",
  routes: [
    
  
    {
      path: "/privacy-policy",
      name: "privacy.policy",
      component: () => import("@/components/about/PrivacyPolicyComponent.vue"),
    },
    {
      path: "/contact",
      name: "contact",
      component: () => import("@/components/contact/ContactComponent.vue"),
    },
   
    {
      path: "/product/:id",
      name: "product.show",
      props: true,
      component: () => import("@/components/product/ProductShowComponent.vue"),
    },

    {
      path: "/product/category/:id",
      name: "product.category.show",
      props: true,
      component: () => import("@/components/category/ProductByCategory.vue"),
    },

    {
      path: "/product/Singlerecipe/:id",
      name: "product.Singlerecipe.show",
      props: true,
      component: () => import("@/components/Singlerecipe/ProductByCategory.vue"),
    },
   

    {
      path: "/my_cart_details",
      name: "cart.details",
      component: () => import("@/components/product/CartComponent.vue"),
    },
    {
      path: "/my_cart_details/place_order",
      name: "cart.place.order",
      component: () => import("@/components/product/PlaceOrder.vue"),
    },
    {
      path: "/my-orders",
      name: "cart.orders",
      component: () => import("@/components/product/OrdersComponent.vue"),
    },
    {
      path: "/my-orders/:id",
      name: "order.detail",
      component: () => import("@/components/product/OrderDetail.vue"),
    },

    {
      path: "/order/information",
      name: "cart.alert",
      component: () => import("@/components/product/CartAlertComponent.vue"),
    },
    {
      path: "/test",
      name: "test",
      component: () => import("@/components/Test.vue"),
    },
    {
      path: "/profileinfo",
      name: "profileinfo",
      component: () => import("@/components/profile/Profile.vue"),
      children: [
        {
          path: "/profile",
          name: "profile",
          component: () => import("./components/profile/BasicNav.vue"),
        },
        {
          path: "/change-password",
          name: "changePassword",
          component: () => import("./components/profile/ChangePassword.vue"),
        },
        {
          path: "/update-information",
          name: "updateInfo",
          component: () => import("./components/profile/UpdateInfo.vue"),
        },
      ],
    },
    {
      path: "/auth",
      name: "auth",
      component: () => import("./components/auth/AuthComponent.vue"),
      children: [
        {
          path: "/sign-up",
          name: "signUp",
          component: () => import("./components/auth/SignUpComponent.vue"),
        },
        {
          path: "/log-in",
          name: "signIn",
          component: () => import("./components/auth/SignInComponent.vue"),
        },
        {
          path: "/password/reset",
          name: "passwordReset",
          component: () => import("./components/auth/PasswordReset.vue"),
        },
        {
          path: "/password/reset/:token",
          name: "newPassword",
          component: () => import("./components/auth/NewPassword.vue"),
        },
      ],
    },
    {
      path: "/",
      name: "Home",
      component: () => import("./views/Homeview/Home.vue"),
      children: [ 
      ],
    },
    {
      path: "/About",
      name: "NewAboutus",
      component: () => import("./views/Homeview/AboutUs.vue"),

    },

    {
      path: "/recipe",
      name: "recipe-list",
      component: () => import("./views/Homeview/Recipe.vue"),

    },
    // {
    //   path: "/recipe-list/:id",
    //   name: "Singlerecipe.show",
    //   props: true,
    //   component: () => import("@/components/Singlerecipe/ProductByCategory.vue"),
    // },
    {
      path: "/recipe/:id",
      name: "singleRecipe",
      component: () => import("./components/Singlerecipe/SingleRecipe.vue"),
    },
    {
      path: "/WhereToBuy",
      name: "newWhereToBuy",
      component: () => import("./views/Homeview/WhereToBuy.vue"),
    },
  
        {
          path: "/products",
          name: "products-list",
          props: true,
          component: () => import("@/components/estore/ProductByCategory.vue"),
        },
        {
          path: "/products/:id",
          name: "newestore.newproduct.show",
          props: true,
          component: () => import("@/components/estore/ProductShowComponent.vue"),
        },
        {
          path: "/products/discount-product/:id/products",
          name: "offerpage",
          props: true,
          component: () => import("@/components/OfferPage.vue"),
        },
    
  ],
});
routes.beforeEach(function (to, from, next) {
  window.scrollTo(0, 0);
  next();
});
export default routes;
