import axios from 'axios'
const product = {
  namespaced: true,
  state: {
    products: [],
    gbProducts:[],
    isSearched: 0,
    categoryProducts: [],
    filterProducts: [],
    filteredProducts: [],
    loader: false,
    lastPage: '',
    currentPage: '',
    lastCategoryId: null,
  },
  getters: {
    // ...
    GET_PRODUCTS: (state) => {
      return state.products
    },

    lastCategoryId: state => state.lastCategoryId,
    
    GET_FILTER_PRODUCTS: (state) => {
      return state.filterProducts
    }
  },
  mutations: {
    setLastCategoryId(state, categoryId) {
      state.lastCategoryId = categoryId;
    },
    SET_PRODUCTS(state, data){
        state.products = []
        state.products = data
    },

    SET_FILTERED_PRODUCTS(state, data){
      if(data.current_page == 1){
        state.filteredProducts = data.data
      }else{
        state.filteredProducts = [...state.filteredProducts, ...data.data];
      }
    },
    SET_LAST_PAGE(state, data) {
      state.lastPage = data;
    },
    SET_CURRENT_PAGE(state, data) {
      state.currentPage = data;
    },

    SET_FILTER_PRODUCTS(state,key){
      var items = state.products.filter(item =>{
        if(item.name.toLowerCase().match(key.toLowerCase())){
            return item;
          }
        })
      state.filterProducts = items
    },
    SET_LOADING(state, data) {
      state.loader = data;
    },

    
    SET_PRODUCTS_BY_CAtEGORY(state, data){
      function numeric(a, b) {

        if(a.serial == null){
         a.serial = 99999
        }
        
        if(b.serial == null){
         b.serial = 99999
        }
        
        return parseInt(a.serial) - parseInt(b.serial);
      } 
      state.categoryProducts = data.sort(numeric);
            // state.categoryProducts = data;

    },
    SET_PRODUCTS_BY_gb_CAtEGORY(state, data){
      function numeric(a, b) {

        if(a.serial == null){
         a.serial = 99999
        }
        
        if(b.serial == null){
         b.serial = 99999
        }
        
        return parseInt(a.serial) - parseInt(b.serial);
      } 
      state.gbProducts = data.sort(numeric);
            // state.categoryProducts = data;

    }


  },

  actions: {
    async ACT_PRODUCTS_LIST({commit}){
      let response = ''
       response = await axios.get(`/e-stores/all`)
       if(response.data.success) {
        commit('SET_PRODUCTS', response.data.data);
       }
       //
    },

    async ACT_FILTER_PRODUCTS({commit}, payload){
      let response = ''
      commit('SET_LOADING', true)
      response = await axios.get(`/e-stores/filter-products` + payload)
      if(response.data.success) {
        commit('SET_LAST_PAGE', response.data.data.last_page);
        commit('SET_CURRENT_PAGE', response.data.data.current_page);
        commit('SET_FILTERED_PRODUCTS', response.data.data);
        commit('SET_LOADING', false)
      }
      commit('SET_LOADING', false)
    },
    
    async ACT_PRODUCTS_BY_CATEGORY({state, commit}, category_id){
      let response = ''
      state.tempProducts = []
      state.categoryProducts = []
      state.loader = true
      response = await axios.get(`/e-stores/all?category=${category_id}`)
       state.loader = false
       if(response.data.success) {
        commit('SET_PRODUCTS_BY_CAtEGORY', response.data.data);
       }
       
       //
    },
    async ACT_PRODUCTS_BY_GB_CATEGORY({state, commit}, cat_id=1){
      let response = ''
      state.tempProducts = []
      state.gbProducts = []
      state.loader = true
      response = await axios.get(`/products/all?category=${cat_id}`)
       state.loader = false
       if(response.data.success) {
        commit('SET_PRODUCTS_BY_gb_CAtEGORY', response.data.data);
       
       }
       commit('setLastCategoryId', cat_id);
       
       //
    },
    

  },
  modules: {
  }
}

export default product