<template>
  <div class="main">
    <!-- <img class="hello d-none d-xl-flex" src="/img/new-image/group.svg" />  -->
    <div class="b-border"></div>
    <div class="container">
      <div class="">
        <GoTop src="/img/new-image/top.png"  right="-40"></GoTop>
      </div>
      <div class="flex justify-between">
  <div class="text-right">
    <a href="https://www.facebook.com/germanbutcherbd" target="__blank">
    <img
      src="/img/new-image/esmessenger.png"
      alt=""
      class="social-1"
    />
  </a>
  </div>
  <div class="  mr-3 mr-md-0 mt-sm-2 ">
    <a href="https://wa.me/+8801911080825" target="__blank"
      ><img src="/img/new-image/swhps.png" alt="" class="social-2"
    /></a>
  </div>
</div>
<div class="">
<div class=" ">
</div>
<div class="text-center" style="margin-top:0px">
  <div class=" d-block ">
    <div class="text-center mt-lg-0 mt-sm-3">
      <img
        src="/img/logo.png"
        class="m-image img-fluid text-center"
        style=""
      />
    </div>
    <div class="text-center items-center  " style=" text-align: center; white-space:nowrap ;  ">
      <nav class="navbar navbar-expand-lg ">
        <div class="text-center " style="margin: auto;">
          <ul class="d-flex " style="list-style:none">
            <li class="nav-item active">
              <router-link
                :to="{ name: 'NewAboutus' }"
                class="nav-link"
                style="
                  color: white;
                  font-size: 16px;
                  font-weight: bold;
                "
              >
                Our Story
                <span class="sr-only">(current)</span></router-link
              >
            </li>
            <li class="nav-item active px-2">
              <router-link
                :to="{ name: 'contact' }"
                class="nav-link"
                style="
                  color: white;
                  font-size: 16px;
                  font-weight: bold;
                "
              >
                Contact Us
                <span class="sr-only">(current)</span></router-link
              >
            </li>
            <li class="nav-item active">
              <router-link
                :to="{ name: 'privacy.policy' }"
                class="nav-link"
                style="
                  color: white;
                  font-size: 16px;
                  font-weight: bold;
                "
              >
                Privacy Policy
                <span class="sr-only">(current)</span></router-link
              >
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
  <div class="text-center">
    <img
      class="pt-2 px-2 img-fluid"
      src="/img/new-image/halal.svg"
      alt=""
    />
    <img
      class="pt-2 px-2 img-fluid"
      src="/img/new-image/germanlogo.svg"
      alt=""
    />
    <img
      class="pt-2 px-2 img-fluid"
      src="/img/new-image/Frame.svg"
      alt=""
    />
  </div>
  <div class="text-center">
    <a target="_blank" href="https://www.facebook.com/germanbutcherbd">
    <img
      class="pt-2 mt-2 px-2 img-fluid"
      src="/img/new-image/facebook.svg"
      alt=""
    />
    </a>
    <a>
    <!-- <img
      class="pt-2 mt-2 px-2 img-fluid"
      src="/img/new-image/linkedin.svg"
      alt=""
    /> -->
  </a>
  <a >
    <img
      class="pt-2 mt-2 px-2 img-fluid"
      src="/img/new-image/youtube.svg"
      alt=""
    />
  </a>
    <!-- <a>
    <img
      class="pt-2 mt-2 px-2 img-fluid"
      src="/img/new-image/twitter.svg"
      alt=""
    />
  </a> -->
  <a target="_blank" href="https://www.instagram.com/germanbutcherbd">
    <img
      class="pt-2 mt-2 px-2 img-fluid"
      src="/img/new-image/instagram.svg"
      alt=""
    />
  </a>
  </div>
  <div class="text-center">
    <p class="footer-1 mt-2">
      Copyright@ German Butcher. 
    </p>
  </div>
</div>

</div>
    </div>
  </div>
</template>

<script>

import GoTop from "@inotom/vue-go-top";

export default {
  name: "Footer",
  components: {
    GoTop,
  },


}
</script>

<style scoped>
.main{
width: 100%;
height: 360px;
background: #000000;
position: relative;
margin-top: 20px;
}
.cent{
  margin-left: 35%;
}
.hello{
    position: absolute;
    right: 0;
    left: 0;
}
@font-face {
  font-family: 'Quicksand';
  src: url('../../../public/fonts/Quicksand-VariableFont_wght.ttf');
} 
@font-face {
  font-family: 'CastorTwoW01-Regular';
  src: url('../../../public/fonts/Castor\ Two\ W01\ Regular.ttf');
} 

*,
::before,
::after {
box-sizing: border-box;
}
body {
}


/* .b-image {

position: relative;
left: -74%;
right: .86%;
margin-top: 180px;

} */

.b-border {
background: rgb(255, 236, 167);
background: linear-gradient(270deg, #d29835 0%, #f9ecc0 53.12%, #d29835 100%);
height: 10px;
}
.m-image {
width: 6.4rem;
height: 6rem;
}
.txt-1 {
font-family: "Quicksand";
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 28px;
/* or 117% */
text-align: right;
letter-spacing: -0.04em;

color: #ffffff;
}
.social-1 {
  height: 60px;
margin-top: -40px;
margin-right: -16px;
}

.social-2 {
  height: 60px;
  margin-left: 88%;
  margin-top: -100px;
}

.social-3 {
margin-top: -80px;
margin-left: -80px;
}
.sect-3 {
margin-top: 7.5rem;
margin-right: -15px;
}

.footer-1 {
font-style: normal;
color: #fff;
font-weight: 600;
font-size: 16px;
line-height: 24px;
}
.container{
  padding: 0 !important;
}
@media (min-width: 1024px) and (max-width: 1230px){
  .social-1{
    margin-top: -40px !important;
  }
.social-2{
    margin-top: -100px !important;
  }

}

@media (min-width: 1281px) and (max-width: 1430px) {
  .hello{
    position: absolute !important;
    right: 0 !important;
    width: 100%;
    height: 100%;
}
  
}

@media only screen and (min-width: 1440px) {


.section-2{
margin-left: 0px !important;
text-align: center;
} 
.social-1{
  margin-top: -50px;
margin-right: -100px;
}
.social-2{
 
margin-left: 97%;

}
.social-3 {
margin-top: -80px;
margin-left: -200px;
}
.sect-3{
  margin-right: -100px;
}

}
@media only screen and (max-width: 1280px) {

.hello{
  position:fixed;
}
}

@media only screen and (max-width: 760px) {
.nav-link {
  padding: 0 !important;
}
.first {
  order: 1;
}
.second {
  order: 2;
  margin-top: 0;
}
.social-1 {
  margin-right: 10px;
  height: 40px;
  width: 40px;
}

.social-2 {
  margin-left: 75%;
  margin-top: -80px;
  height: 40px;
  width: 40px;
}

.m-image{
  margin-top: 10px;
  width: 4rem !important;
    height: 4rem !important;
    
}
.sect-3 {
  margin-top: 5px !important;
  margin-right: 0px;
}
.txt-1 {
  text-align: center;
  font-size: 16px;
  margin-top: -2rem;
}
.downapp {
  display: block;
  text-align:center ;
  
}

.section-2{
 display: block;
 margin-left: 100px;
  text-align:center !important;
}
.f-nav {
  margin-left: 35% !important;
  font-size: 12px;

}
.footer-1 {
  font-size: 14px;
  margin: 5px;
}

}

@media (min-width: 970px) and (max-width: 1270px) {
.cent{
  text-align: center;
  margin-left: 20%;
}

}
@media (min-width: 600px) and (max-width: 767px) {
.container{
  padding: 0 !important;
}
.first{
  order: 2;
}
.second{
  order: 1;
}
.m-image{
  text-align: right !important;
  margin-left: -100px !important;
}
.section-2{
 display: block;
 margin-left: 70px;
  text-align:center !important;
}

.bag-1{
  height: auto;
}
.sect-3{
  margin-top: 5rem !important;
  margin-left: 5rem;
}
}
@media (min-width: 768px) and (max-width: 990px) {

.m-image {
  margin-top: 10px !important;
  width: 4rem !important;
    height: 4rem !important;
    text-align: center;
    
}
.section-2 {
 margin-right: 20px !important;
  margin-top: -20px !important;
}
.cent{
 text-align: center;
margin-left: 25%;
} 
}

</style>