import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import order from './order'
import map from './map'
import chat from './chat'
import product from './product'
import recipe from './recipe'
import contact from './contact'
import category from './category'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    cart: localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")) : [],
    userId: '',
  },
  getters: {
    cart_Info: (state) => {
      return state.cart
    },

  },
  mutations: {
    setUserAfterLogIN (state) {
      let userId = localStorage.getItem("fk_uses_id")
      state.userId = userId
    },
    removeUserAfterLogOut (state) {
      state.userId = null
    },
    addTCart: (state, productItem) => {
        var productExists = false;
        for (var i = 0; i < state.cart.length; i++) {
          var currItem = state.cart[i];
          if (currItem.product.products_id == productItem.products_id) {
            state.cart[i].count = state.cart[i].count + 1
            // state.cart[i].totalPrice =currItem.product.price *state.cart[i].count
            productExists = true;
            return 'product added';
            //break;
          }
        }
  
        if (!productExists) {
          state.cart.push({ product: productItem, count: 1 })
          return  'product updated'
        }
  
      },
  
      cartValueClear: (state) => {
        state.cart = [];
      },
  
      cartItemRemove: (state, index) => {
  
        // console.log(typeof state.cart);
         state.cart.splice(index,1);
        // console.log(state.cart);
      }
  },
  actions: {
    update_cartInfo: ({ commit, state }, newValue) => {
        commit('set_cartInfo', newValue)
        return state.cart
      },
  },
  modules: {
    user, 
    order,
    category,
    map,
    contact,
    chat,
    product,
    recipe
  }
})
