<template>
  <div style="z-index: 3;">
  <div class="row">
  <div id="app" class="col-5">
    <v-app style="background: black; min-height: 100%;" class="header-1 col-sm-12 col-md-6">
      <v-navigation-drawer
       
        style="background: black; z-index: 2;"
        ref="drawer"
        app
        left
        hide-overlay
        :width="navigation.width"
        v-model="navigation.shown"
        
      >
        <div class="text-center d-flex" style="margin-top: 30px; margin-left: 40px;">
          <router-link
        
          :to="{ name: 'Home' }"
              
              >
          <img
            class="img-fluid img-border h-70 w-50"
            src="/img/new-image/logo3.png"
            alt=""
          >
        </router-link>
          <v-btn
          icon
          @click="navigation.shown = !navigation.shown"
          style="margin-left: 20px;"
        >
          <v-icon style="color:#c0272d; margin-left: -20px; margin-top: -30px;font-size: 28px;">mdi-close</v-icon>
        </v-btn>
        </div>
        
        <div>
          <ul class="text-left mt-5" style="list-style: none ; margin-left: 10px;">
            <li class="active text-white " style="border: none; padding: 7px 0px">
              <router-link
                class="font-1"
                :to="{ name: 'Home' }"
                exact-active-class="_active"
                active-class="none"
              >
              <i class="fa fa-home px-2" aria-hidden="true"></i> Home
              </router-link>
            </li>

            <li
              @click.prevent
              class="active mega-menu-position top-hover "
              style="border: none; padding: 7px 0px"
              
            >
        
                        
              <router-link
                class="font-1"
                :to="{ name: 'products-list'}"
                :class="{ active: $route.path.startsWith('/products') }"
                exact-active-class="_active"
                active-class="none"
                ><i class="fa fa-shopping-basket px-2" ></i>Products</router-link
              >
            </li>

            
            <li
            @click="reloadPage"
              @click.prevent
              class="mega-menu-position top-hover r-ds "
              style="border: none; padding: 7px 0px"
            >
              <router-link
                class="font-1"
                :to="{ name: 'recipe-list' }"
                exact-active-class="_active"
                active-class="none"
                :class="{ active: isRecipeActive }"
              >
              <i class="fa fa-cutlery px-2" ></i> Recipe
              </router-link>
            </li>
            <li style="border: none; padding: 7px 0px" class="r-ds ">
              <router-link class="font-1" :to="{ name: 'newWhereToBuy' }"
              exact-active-class="_active"
               
                ><i class="fa fa-map-marker px-2 ml-1" ></i>Where to Buy</router-link
              >
            </li>

          </ul>
        </div>
        <div class="mt-5" style="margin-left:8px">
          <ul class="d-block" v-if="loggedIn">
            <li class="px-lg-1 px-xl-1">
              <router-link :to="{ name: 'profile' }">
                <button
                  type="button"
                  class="font-1 px-lg-1 px-xl-2 py-xl-1"
                  style="color: white"
                >
                <i class="fa fa-user-md px-2" aria-hidden="true"></i>
                  Profile
                </button>
              </router-link>
            </li>
            <li class="px-lg-1 px-xl-1" style="padding:7px 0px">
              <router-link :to="{ name: 'cart.orders' }">
                <button
                  type="button"
                  class="font-1 px-lg-1 px-xl-2 py-xl-1"
                  style="white-space: nowrap"
                >
                <i class="fa fa-shopping-bag px-2" aria-hidden="true"></i>My Orders
                </button>
              </router-link>
            </li>
            <li class="px-lg-1 px-xl-1" style="padding:7px 0px">
              <a href @click.prevent="logOut()"
                ><button type="button" class="font-1 px-lg-1 px-xl-2 py-xl-1">
                    <i class="fa fa-sign-out px-2" aria-hidden="true"></i>Logout
                </button></a
              >
            </li>
            
          </ul>
          <ul
            class="text-left d-block hide"
            style="color: white; list-style: none"
            v-else
          >
            <li class="px-lg-1 px-xl-1">
              <router-link :to="{ name: 'signUp' }"
                ><button type="button" class="font-1 px-2 py-1">
                    <i class="fa fa-user-plus px-1" aria-hidden="true"></i>Register
                </button></router-link
              >
            </li>
            <li class="px-lg-1 px-xl-1" style="padding:7px 0px">
              <router-link :to="{ name: 'signIn' }"
                ><button
                  type="button"
                  class="font-1 box px-2 py-1"
                  style="color: white"
                >
                <i class="fa fa-sign-in px-1" aria-hidden="true"></i>Login
                </button></router-link
              >
            </li>
            
          </ul>
        </div>
      </v-navigation-drawer>
      <v-container style="margin-top:10px; " class="justify-content-center mt-md-5">
        <v-layout justify-left>
          <v-btn @click="navigation.shown = !navigation.shown" style="background:black"
            ><i class="fa fa-bars font-3" style="color:#c0272d; font-size: 30px;"></i></v-btn
          >
        </v-layout>
      </v-container>
    </v-app>
  </div>
  <div class="d-lg-none d-block col-3 align-item-end" style="margin-top: 12px;">
    <router-link to="/">
    <img
            class="img-fluid img-border w-20 mb p-2"
            src="/img/new-image/logo3.png"
            alt=""
          />
    </router-link>
  </div>   
</div>
</div>
</template>
  <script>
  import $ from "jquery";
export default {
  data: () => {
    return {
      navigation: {
        shown: false,
        width: 220,
        borderSize: 1,
        drawer:true
      },
    };
  },
  computed: {
    isParentActive() {
      return (
       
        this.$route.path.startsWith('/estore/')

        )
    
    },
    isRecipeActive() {
      return (
       
        this.$route.path.startsWith('/recipe/')

        )},
    total: function () {
      return this.$store.state.cart.length;
    },
    loggedIn: function () {
      let uses_id = this.$store.state.userId;
      if (uses_id == null) {
        return false;
      } else {
        return true;
      }
      //return ((token !== null) ? true : false)
    },
  },
  filters: {
    limitCharacter(item) {
      if (item.length > 13) {
        return item.substring(0, 13) + ".......";
      } else {
        return item;
      }
    },
  }, //end of filter
  methods: {
    check() {},
    reloadPage() {
      window.location.href='/recipe';
    },

    goTo(id) {
      this.$router.push({ name: "product.category.show", params: { id: id } });
    },
    logOut() {
      localStorage.removeItem("userToken");
      localStorage.removeItem("fk_uses_id");
      this.$store.commit("removeUserAfterLogOut");
      this.$notify({
        group: "logout",
        type: "error",
        title: "Logged out successfully",
        //text: 'Hello user! This is a notification!'
      });
      this.$router.push({ name: "Home" });
    },
  }, //methods end here

  mounted() {
    this.$store.commit("setUserAfterLogIN");
    var header = $(".transparent-bar");
    var win = $(window);

    /*header.wrap('<div class="place-holder"></div>');
            $('.place-holder').height(header.outerHeight());*/

    win.on("scroll", function () {
      var scroll = win.scrollTop();
      if (scroll < 200) {
        header.removeClass("stick");
      } else {
        header.addClass("stick");
      }
    });

    $(".search-wrap button").on("click", function (e) {
      e.preventDefault();
      $(this).parent().find(".search").slideToggle("medium");
    });
  }, //mounted ends here
};
</script>
<style scoped>
@font-face {
  font-family: 'Quicksand';
  src: url('../../../public/fonts/Quicksand-VariableFont_wght.ttf');
} 
@font-face {
  font-family: 'CastorTwoW01-Regular';
  src: url('../../../public/fonts/Castor\ Two\ W01\ Regular.ttf');
} 
::v-deep .v-application--wrap {
    min-height: fit-content;
  }
::v-deep .mystyle{
  min-height: 0vh!important;
    margin-top: 0px;
}
::v-deep .mystyle2{
  min-height: 0vh!important;
    margin-top: 0px;
}
.row{
  margin: 0px !important;
}
.font-1 {
  color: white;
  font-size: 18px;
  text-transform: uppercase;
}
.active{color:#e54c2a;}
._active{

color:#e54c2a;

}
.main-menu .router-link-exact-active {
  color:#e54c2a;
}
.main-menu .router-link-active {
  color:#e54c2a;
}

.main-menu ul li a {
font-size: 18px;
}
._active{

color:#e54c2a;
}
.main-menu .router-link-exact-active {
  color:#e54c2a;
}
.main-menu .router-link-active {
color:#e54c2a;

}
ul li:hover{
    color: #c0272d;
}

@media (min-width: 768px) and (max-width: 912px) {
  .font-3{
    font-size: 35px !important;
  }
}
@media (max-width: 767px){
  .font-3{
    margin-left: -30px;
  }

}
</style>