import axios from 'axios'
const chat = {
  namespaced: true,
  state: {
    token: '',
    userId: '',
    ticket: [],
  },
  getters: {
    // ...
    GET_TICKET: (state) => {
      return state.ticket
    },
  },
  mutations: {
    SET_TOKEN(state){
        let token = localStorage.getItem("userToken")
        state.token = token
    },
    SET_TICKET(state,data){
        state.ticket = data
    },
    SET_USER_ID(state){
        let id = localStorage.getItem("fk_uses_id")
        state.userId = id
    }
  },
  actions: {
    async ACT_TICKET_LIST({state, commit}, id){
        commit('SET_TOKEN')
        let response = await axios.get(`/orders/supports/all?order_id=${id}&token=${state.token}`)
        if(response.data.success == true) commit('SET_TICKET', response.data.data)
    },
    

    async ACT_STORE_MESSAGE({state,commit, dispatch}, payload){
        commit('SET_TOKEN')
        payload.token = state.token
        return new Promise( (resolve, reject) => {
            axios.post(`/orders/supports/replies/store`, payload)
            .then(data => {
                if(data.data.success == false){
                  dispatch('ACT_TICKET_LIST', data.data.data.order_support_id)
                   // resolve(data.data);
                }else {
                    resolve(data.data);
                }
            })
            .catch(error => {
                reject(error)
            });
        });
    },


    async ACT_STORE_TICKET({state,commit, dispatch}, payload){
        commit('SET_TOKEN')
        payload.token = state.token
        return new Promise( (resolve, reject) => {
            axios.post(`/orders/supports/store`, payload)
            .then(data => {
                if(data.data.success){
                  dispatch('ACT_TICKET_LIST', data.data.data.order_id)
                  resolve(data.data);
                }else {
                    resolve(data.data);
                }
            })
            .catch(error => {
                reject(error)
            });
        });
    },

    
    

  },
  modules: {
  }


}

export default chat