import axios from 'axios'
const order = {
  namespaced: true,
  state: {
    token: '',
    userId: '',
    orders: [],
    order: [],
  },
  getters: {
    // ...
    GET_ORDER: (state) => {
      return state.order
    },
    GET_ORDERS: (state) => {
      return state.orders
    }
  },
  mutations: {
    SET_TOKEN(state){
        let token = localStorage.getItem("userToken")
        state.token = token
    },
    SET_ORDER(state,data){
        state.order = data
        
    },
    SET_ORDERS(state,data){
        state.orders = data
    },
    SET_USER_ID(state){
        let id = localStorage.getItem("fk_uses_id")
        state.userId = id
    }
  },
  actions: {
    async ACT_ORDER({state, commit}, id){
        commit('SET_TOKEN')
      let response = await axios.get(`/orders/${id}/details?token=${state.token}`)
      if(response.data.success) commit('SET_ORDER', response.data.data)
    },
    async ACT_ORDER_LIST({state, commit}, page){
        commit('SET_USER_ID')
        commit('SET_TOKEN')
       let response = await axios.get(`/orders/all?token=${state.token}&page=${page}`)
       if(response.data.success) commit('SET_ORDERS', response.data.data)
    },

    async ACT_CANCEL_ORDER({state,commit, dispatch}, id){
        commit('SET_TOKEN')
        return new Promise( (resolve, reject) => {
            axios.get(`/orders/${id}/cancel?token=${state.token}`)
            .then(data => {
                if(data.data.success){
                  dispatch('ACT_ORDER_LIST')
                    resolve(data.data);
                }else {
                    resolve(data.data);
                }
            })
            .catch(error => {
                reject(error)
            });
        });
    },

    
    

  },
  modules: {
  }


}

export default order