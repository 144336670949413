import axios from 'axios'
const user = {
  namespaced: true,
  state: {
    token: '',
    userId: '',
    user: [],
    tempUser: [],
  },
  getters: {
    // ...
    GET_USER: (state) => {
      return state.user
    },
    GET_TEMP_USER: (state) => {
      return state.tempUser
    }
  },
  mutations: {
    SET_TOKEN(state){
        let token = localStorage.getItem("userToken")
        state.token = token
    },
    SET_USER(state,data){
        state.user = data
        state.tempUser = Object.assign({}, state.user)
    },
    SET_USER_ID(state){
        let id = localStorage.getItem("fk_uses_id")
        state.userId = id
    }
  },
  actions: {
    async ACT_USER({state, commit}){
        //console.log('action')
        commit('SET_TOKEN')
       let response = await axios.get(`/users/profile?token=${state.token}`)
       if(response.data.success) commit('SET_USER', response.data.data)
    },

    async ACT_UPDATE_USER({state, commit}, payload){
        commit('SET_TOKEN')
        //payload.token = state.token
        axios.defaults.headers.common['X-HTTP-Method-Override'] = 'put'
        return new Promise( (resolve, reject) => {
            axios.post(`/users/${payload.id}?token=${state.token}`, payload)
            .then(data => {
                if(data.data.success){
                    commit('ACT_USER')
                    resolve(data.data);
                }else {
                    resolve(data.data);
                }
            })
            .catch(error => {
                reject(error)
            });
        });
    },

    async ACT_CHANGE_PASS({state, commit}, payload){
        commit('SET_TOKEN')
        commit('SET_USER_ID')
        axios.defaults.headers.common['X-HTTP-Method-Override'] = 'put'
        payload.user_id = state.userId
        payload.token = state.token
        
        return new Promise( (resolve, reject) => {
            //console.log(payload, ">>>>>>>fdfdf>>>")
            axios.post("/users/update/password", payload)
            .then(data => {
                if(data.data.success){
                    resolve(data.data);
                }else {
                    resolve(data.data);
                }
            })
            .catch(error => {
                reject(error)
            });
        });
    }




    

  },
  modules: {
  }


}

export default user