import axios from 'axios'
const category = {
  namespaced: true,
  state: {
    loader: false,
    categoryId : '',
    gbCategoryId: '',
    gbSubcategories: '',
    categories: [],
    gbCategories: [],
    eCategories: [],
    recipeCategories: [],
    subcategories: [],
    category: '',
    chield: '',
    
  },
  getters: {
    // ...
    GET_GB_CATEGORIES: (state) => {
      return state.gbCategories
    },
    GET_CATEGORIES: (state) => {
      return state.categories
    },
    GET_GB_SUBCATEGORIES: (state) => {
      return state.gbSubcategories
    },
    GET_E_CATEGORIES: (state) => {
      return state.eCategories;
      // return ecat;
      // if(state.eCategories.length > 0){
      //   return state.eCategories
      // }else{
      //   function resolveLater(resolve, reject) {
      //     setTimeout(function() {
      //       resolve(state.eCategories);
      //     }, 1000);
      //   }
      //   var p1 = new Promise(resolveLater);
      //   return p1;
      // }
      
       
    },

    GET_RECIPE_CATEGORIES: (state) => {
      return state.recipeCategories
    },
    GET_SUBCATEGORIES: (state) => {
      return state.subcategories
    },
    
  },
  mutations: {
    
    SET_GB_CATEGORIES(state,data){
      //state.gbCategories = data
      function alphabetic(a, b) {
        if(parseInt(a.serial_no)  < parseInt(b.serial_no)) {
          return -1;
        }
        if(parseInt(a.serial_no) > parseInt(b.serial_no)) {
          return 1;
        }
        
        return 0;
      }
      function numeric(a, b) {
        if(parseInt(a.sub_serial) < parseInt(b.sub_serial)) {
          return -1;
        }
        if(parseInt(a.sub_serial) > parseInt(b.sub_serial)) {
          return 1;
        }
        
        return 0;
      }

      state.gbCategories = data.sort(alphabetic).map(i => {

        if(i.children.length > 0) {
          let child = i.children.sort(numeric);
          i.children = child;
        }
        return i;
      })  
    },

    SET_E_CATEGORIES(state,data){ 
      function alphabetic(a, b) {
        if(parseInt(a.serial_no) < parseInt(b.serial_no)) {
          return -1;
        }
        if(parseInt(a.serial_no) > parseInt(b.serial_no)) {
          return 1;
        }
        
        return 0;
      }
      function numeric(a, b) {
        if(parseInt(a.sub_serial) < parseInt(b.sub_serial)) {
          return -1;
        }
        if(parseInt(a.sub_serial) > parseInt(b.sub_serial)) {
          return 1;
        }
        
        return 0;
      }
      state.eCategories = data.sort(alphabetic).map(i => {

        if(i.children.length > 0) {
          let child = i.children.sort(numeric);
          i.children = child;
        }
        return i;
      })  
    },

    SET_RECIPE_CATEGORIES(state,data){
      function alphabetic(a, b) {
  
        if(parseInt(a.serial_no) < parseInt(b.serial_no)) {
          return -1;
        }
        if(parseInt(a.serial_no) > parseInt(b.serial_no)) {
          return 1;
        }
        
        return 0;
      }
      function numeric(a, b) {
        if(parseInt(a.sub_serial) < parseInt(b.sub_serial)) {
          return -1;
        }
        if(parseInt(a.sub_serial) > parseInt(b.sub_serial)) {
          return 1;
        }
        
        return 0;
      }
      state.recipeCategories = data.sort(alphabetic).map(i => {

        if(i.children.length > 0) {
          let child = i.children.sort(numeric);
          i.children = child;
        }
        return i;
      })  
    },

    
    SET_CATEGORIES(state,data){
      function alphabetic(a, b) {
  
        if(parseInt(a.serial_no) < parseInt(b.serial_no)) {
          return -1;
        }
        if(parseInt(a.serial_no) > parseInt(b.serial_no)) {
          return 1;
        }
        
        return 0;
      }
        state.categories = data.sort(alphabetic).map(i => {
          if(i.children.length > 0) {
            let child = i.children.sort(alphabetic);
            i.children = child;
          }
          return i;
        })
    },

    SET_SUBCATEGORIES(state, data){
      
      let subcat = data.filter(item => item.categories_id == state.categoryId)
      state.subcategories = subcat[0]

    },
    SET_GB_SUBCATEGORIES(state, data){
      let subcat = data.filter(item => item.categories_id == state.gbCategoryId)
      state.gbSubcategories = subcat[0]

    },
    
  },


  actions: {
    async ACT_E_CATEGORIES_LIST({commit}){
       let response = await axios.get(`categories?type=${'e-store'}`)
       if(response.data.success) commit('SET_E_CATEGORIES', response.data.data)
    },
    async ACT_GB_CATEGORIES_LIST({commit}){
       let response = await axios.get(`categories?type=${'gb-product'}`)
       if(response.data.success) commit('SET_GB_CATEGORIES', response.data.data)
    },
    async ACT_RECIPE_CATEGORIES_LIST({commit}){
       let response = await axios.get(`categories?type=${'recipe'}`)
       if(response.data.success) commit('SET_RECIPE_CATEGORIES', response.data.data)
    },

    async ACT_CATEGORIES_LIST({commit}, type){
       let response = await axios.get(`categories?type=${type}`)
       if(response.data.success) commit('SET_CATEGORIES', response.data.data)
    },
    
    async ACT_CATEGORIES({state, commit}, query){
       
       state.loader = true
       state.categoryId = query.id
      
       let response = await axios.get(`categories?type=${query.type}`)
       state.loader = false
       
       if(response.data.success) commit('SET_SUBCATEGORIES', response.data.data)
    },


    async ACT_CATEGORIES_FOR_GB_PRODUCT({state, commit}, query){
      state.loader = true
      
       state.gbCategoryId = query.id
       let response = await axios.get(`categories?type=${query.type}`)
       state.subcategories = []
       state.loader = false
       
       if(response.data.success) commit('SET_GB_SUBCATEGORIES', response.data.data)
    },
    


  },
  modules: {
  }


}

export default category