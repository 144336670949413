<template>
  <div class="main">
    <div class="contain">
      <header class=" m-0 row mr-n1 mt-n1 mb-n1">
        <div class="col-lg-1 col-md-1 col-sm-2 col-xs-2 d-lg-block d-md-none mt-1 mb-2 text-lg-none hide-m-screen res-1" style=" ">
          <div class="text-center">
            <router-link
                to="/"
                exact-active-class="_active"
                active-class="none"
              >
            <img
              class="img-fluid img-border text-center"
              src="/img/new-image/logo3.png"
              alt=""
            />
            </router-link>
          </div>
        </div>
        <div class="col-xl-7 col-lg-7 col-sm-6 d-inline align-self-center hide-m-screen mt-2 second">
          <div class="container" style="padding: 16px 12px 6px 12px !important; ">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12 col-sm-12 align-self-center" style="padding: 0;">
                <div class="menu-search-bundle">
                  <div class="main-menu" style="font-family:Nexa bold">
                    <ul
                      class="hide row-lg-3"
                      style="display: flex flex-wrap;"
                    >
                      <li class="active" style="border: none">
                        <router-link class="font-1" :to="{ name: 'Home' }" exact-active-class="_active" active-class="none">
                          Home
                        </router-link>
                      </li>

                      <li
                     
                        class="active mega-menu-position top-hover "
                        style="border: none;"   
                        
                      >
                      <router-link class="font-1" routerLinkActive="active" 
                             :to="{ name: 'products-list'}"
                             :class="{ active: $route.path ==='/products' }"
                    
                             >Products
                        </router-link>
                      </li>

                      <li
                    
                        class="mega-menu-position top-hover r-ds"
                        style="border: none;"
                      >
                        <router-link class="font-1" :to="{ name: 'recipe-list' }" :class="{ active: isRecipeActive }">
                          Recipe
                        </router-link>
                      </li>
                      <li style="border: none;" class="r-ds">
                        <router-link class="font-1" :to="{ name: 'newWhereToBuy' }"
                          >Where to Buy</router-link
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-sm-5 align-self-center hide-m-screen  first res-3" style="">
          <div class="">
            <div class="container">
              <div >
                <div class="hide-m-screen"></div>
                <div class="">
                  <nav
                    class="navbar navbar-expand-lg"
                    style="font-size: 16px; text-align: "
                  >
                    <ul
                      class="
                        row-lg-3
                        d-lg-flex d-xs-none
                        text-xs-center
                        justify-content-between
                        contain-2
                        hide
                      "
                      style="margin-top:0px !important;margin-left:-50px;font-family: Nexa bold; font-weight: 600;"
                      v-if="loggedIn"
                    >
                      <li class="px-lg-1 px-xl-1">
                        <router-link :to="{ name: 'profile' }">
                          <button
                            type="button"
                            class="btn-1 px-lg-1 px-xl-2 py-xl-1 "
                            style="color: white"
                          >
                            Profile
                          </button>
                        </router-link>
                      </li>
                      <li class="px-lg-1 px-xl-1">
                        <router-link :to="{ name: 'cart.orders' }">
                          <button type="button" class="btn-1 px-lg-1 px-xl-2 py-xl-1" style="white-space: nowrap;">
                            My Orders
                          </button>
                        </router-link>
                      </li>
                      <li class="px-lg-1 px-xl-1">
                        <a href @click.prevent="logOut()"
                          ><button type="button" class="btn-1  px-lg-1 px-xl-2 py-xl-1">
                            Logout
                          </button></a
                        >
                      </li>
                      <li class="px-lg-1 px-xl-1">
                        <router-link :to="{ name: 'cart.details' }">
                          <button type="button" class="btn-1 px-lg-1 px-xl-2 py-xl-1">
                            <img src="/img/new-image/cart.svg" class=" mt-xl-1 mt-xxl-1 mt-lg-1 px-1"/>
                          </button>
                        </router-link>
                      </li>
                    </ul>
                    <ul
                      class="
                        col-sm-5 col-xs-12
                        top-menu-item
                        hide-m-screen
                        d-lg-flex d-md-none d-sm-none d-xs-none
                        justify-content-around
                        hide
                        row-lg-3
                      "
                      style="margin-left: 5rem; color: white; list-style: none"
                      v-else
                    >
                      <li class="px-lg-1 px-xl-1">
                        <router-link :to="{ name: 'signUp' }"
                          ><button
                            type="button"
                            class="btn-1 px-2 py-1 d-flex justify-content-center"
                          >
                            Register
                          </button></router-link
                        >
                      </li>
                      <li class="px-lg-1 px-xl-1">
                        <router-link :to="{ name: 'signIn' }"
                          ><button
                            type="button"
                            class="btn-1 box px-2 py-1 d-flex justify-content-start"
                            style="color: white"
                          >
                            Login
                          </button></router-link
                        >
                      </li>
                      <li class="px-lg-1 px-xl-1">
                        <router-link :to="{ name: 'cart.details' }">
                          <button
                            type="button"
                            class="btn-1 px-2 py-1 d-flex justify-content-start"
                          >
                          <img src="/img/new-image/cart.svg" class=" mt-xl-1 mt-xxl-1 mt-lg-1 px-1"/>
                          
                          </button>
                        </router-link>
                      </li>
                    </ul>
                    <!-- </div> -->
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <!-- <div
            style="margin-left: 280px; margin-top: -45px"
            class="d-md-none d-lg-none d-sm-flex"
          >
            <router-link :to="{ name: 'cart.details' }">
              <button
                type="button"
                class="btn-1 px-3 d-flex justify-content-start"
              >
                <i class="fa fa-cart-plus mt-1"></i>({{ total }})
              </button>
            </router-link>
          </div> -->
          <notifications group="logout" position="top left" />
        </div>
      </header>
    </div>
    <div
      style="background: #000000;position:relative"
      class=" d-lg-none " 
    >
      <Sidebar/>
    </div>
    <div class="b-border"></div>
  </div>
</template>

<script>
// import DemoHeader from "./DemoHeader.vue";
import Sidebar from "./Sidebar.vue";
import $ from "jquery";
export default {
  components:{Sidebar},
  data() {
    return {
      //categories: [],
      hoverOn: true,
      img: "https://s3.ap-south-1.amazonaws.com/german-butcher/",
      products: this.$store.state.cart,
      products_mega_munu: [],
     
    };
  },

  props: {
    gbCategories: { required: true },
    eCategories: { required: true },
    recipeCategories: { required: true },
  },
  computed: {
    isParentActive() {
      return (
        this.$route.path===('/products/')
        )
    },
    isRecipeActive() {
      return (
       
        this.$route.path.startsWith('/recipe/')

        )},
    total: function () {
      return this.$store.state.cart.length;
    },
    loggedIn: function () {
      let uses_id = this.$store.state.userId;
      if (uses_id == null) {
        return false;
      } else {
        return true;
      }
      //return ((token !== null) ? true : false)
    },
  },
  filters: {
    limitCharacter(item) {
      if (item.length > 13) {
        return item.substring(0, 13) + ".......";
      } else {
        return item;
      }
    },
  }, //end of filter
  methods: {
    check() {},
    goTo(id) {
      this.$router.push({ name: "product.category.show", params: { id: id } });
    },
    logOut() {
      localStorage.removeItem("userToken");
      localStorage.removeItem("fk_uses_id");
      this.$store.commit("removeUserAfterLogOut");
      this.$notify({
        group: "logout",
        type: "error",
        title: "Logged out successfully",
        //text: 'Hello user! This is a notification!'
      });
      this.$router.push({ name: "Home" });
    },
  }, //methods end here

  mounted() {
    this.$store.commit("setUserAfterLogIN");
    var header = $(".transparent-bar");
    var win = $(window);

    /*header.wrap('<div class="place-holder"></div>');
            $('.place-holder').height(header.outerHeight());*/

    win.on("scroll", function () {
      var scroll = win.scrollTop();
      if (scroll < 200) {
        header.removeClass("stick");
      } else {
        header.addClass("stick");
      }
    });

    $(".search-wrap button").on("click", function (e) {
      e.preventDefault();
      $(this).parent().find(".search").slideToggle("medium");
    });
  }, //mounted ends here
};
</script>

<style scoped>
@font-face {
    font-family: 'Quicksand';
    src: url('../../../public/fonts/Quicksand-VariableFont_wght.ttf');
} 
@font-face {
    font-family: 'CastorTwoW01-Regular';
    src: url('../../../public/fonts/Castor\ Two\ W01\ Regular.ttf');
} 

@font-face {
    font-family: 'Nexa Bold';
    src: url('../../../public/fonts/Nexa\ Bold.otf');
} 

/*@font-face {
      font-family: hederFont;
      src: url('/fonts/MyriadPro-Bold.otf');
    }
  @font-face {
      font-family: cart;
      src: url('/fonts/Ubuntu-R.ttf');
    }*/
/* new design */
.main {
  background: #000;
  color: white;
}
.contain {
  margin-left: 8.44rem;
}
.contain-2 {
  color: white;
  list-style: none;
  /* margin-top: 16px; */
}
.mr-n1 {
  margin-top: -0.5rem !important;
}
.mt-n1 {
  margin-top: -0.5rem !important;
}
.mb-n1 {
  margin-top: -0.5rem !important;
}

.res-1{
padding: 16px 12px 6px 12px !important;
}
.res-3{
  margin-left: -30px;
  padding: 16px 12px 6px 12px !important;
  
}
.font-1{
  font-size: 20px !important;
}
.btn-1 {
  color: #ffffff;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  border: 2px solid transparent;
  background: linear-gradient(rgb(51, 51, 51,1) 0 0) padding-box,
    /*this is your grey background*/
      linear-gradient(
        90deg,
        #cf9333 0.16%,
        #eac348 15.12%,
        #fbf1ca 46.03%,
        #dba939 99.88%
      )
      border-box;
  
  border-radius: 8px;
}

/* a,
ul,
li {
  font-family: "Roboto", sans-serif;
}
.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  
}
.min-wf {
  min-width: 153px;
  top: 26px;
  right: -41px;
}
.min-ws {
  min-width: 245px;
  top: 26px;
  right: -142px;
}
.dropdown-content a {
  color: black;
  padding: 7px 7px 3px 10px;
  text-decoration: none;
  text-align: center;
  display: block;
  line-height: 20px;
  font-size: 12px;
  font-family: "Ubuntu", sans-serif;
} */
.top-hover:hover .dropdown-content {
  display: block;
}
._active{

color:#e54c2a;

}
._active{

  color:#e54c2a;
  border-bottom: 2px solid #e54c2a;
}
.main-menu .router-link-exact-active {
  border-bottom: 2px solid #e54c2a;
}
.main-menu .router-link-active {
  color:#e54c2a;
  border-bottom: 2px solid #e54c2a;
}

.main-menu ul li a {
  font-size: 18px;
  font-weight: 500;
}

.b-border {
  background: rgb(255, 236, 167);
  background: radial-gradient(
    circle,
    rgba(255, 236, 167, 1) 50%,
    rgba(222, 177, 73, 1) 100%
  );
  height: 10px;
}



@media only screen and (max-width: 1200px) {
  
  .contain-2{
    padding-left: 
    0;
  }
  .dropdown-content {
    min-width: 144px;
    right: -31px;
  }

  @media only screen and (max-width: 995px) {
    .res-1 {
      text-align: center !important;
      margin-top: -100px;
    }
    .m-ul {
      display: block !important;
    }
    .hide-m-screen {
      display: none !important;
    }
    .top-menu-item {
      text-align: center !important;

      /* text-align: center !important;
    padding-left: 143px!important;
    margin-top: -45px; */
    }
  
    .hide {
      display: none;
    }
  }
  .m-ul li {
    margin-left: 0;
    display: block;
    color: #fff;
    border-right: none;
    text-align: left;
  }
  .m-ul li a {
    padding: 0 !important;
    line-height: 30px;
  }
  }
  @media only screen and (min-width: 990px) and (max-width: 1279px) {
    .font-1{
      font-size: 12px !important;
    }
    .res-1 {
      text-align: center !important;
      margin-top: 35px !important;
      padding-top: 0 !important;
    }
    .res-3{
      margin-left: -45px;
    }
    .main-menu ul li{

      padding-right:.5rem !important ;
      padding-left:1rem !important ;
    }
    .contain-2{
      margin-left: 10px !important;
      /* margin-top: 22px; */
    }
    nav{
      font-size: 14px !important;
      padding: 0px;
    }
    .btn-1{
      font-size: 14px !important;
      padding: 0px;
    }
   
    .main-menu {
      padding: 0;
    }
  }
  @media only screen and (max-width: 950px) {
    .contain{
      margin-left: 0px !important
      ;
    }
  }
  @media only screen and (max-width: 760px) {
    .hide-m-screen {
      display: none !important;
    }
    .contain{
      margin-left: 0px !important
      ;
    }
    /* .res-2 {
      margin-top: -250px;
      margin-left: 0px !important
      ;
    } */
   
    .first {
      order: 1;
    }
    .second {
      order: 2;
    } 
    .hide-m-screen {
      display: none !important;
    }
    .btn-1 {
      font-size: 12px;
    }
    .contain {
      margin-left: 0;
      margin-top: 0;
    }
    .contain-2 {
      margin-right: 0;
      margin-top: 0;
    }
  }

</style> 