import axios from 'axios'
const recipe = {
  namespaced: true,
  state: {
    searchState: 0,
    catId: '',
    recipe: [],
    recipes: [],
    filteredRecipes: [],
    categories: [],
  },
  getters: {
    // ...
    GET_RECIPES: (state) => {
      return state.recipes
    },
    GET_RECIPE: (state) => {
      return state.recipe
    },
    GET_CATEGORIES: (state) => {
      return state.categories
    },

  },
  mutations: {
    SET_RECIPES(state, data){
        state.recipes = data
    },
    SET_RECIPE(state, data){
        var recipe = data.filter(item =>{
            if(item.id == state.catId){return item}
        })
        state.recipe = recipe[0]

    },

    SET_CATEGORIES(state, data){
        state.categories = data
    },
    RECIPE_FILTER_BY_CATEGORY(state,id){
        if(state.searchState == 1){
            var items = state.filteredRecipes.filter(item =>{
                if(item.category_id == id){
                    return item;
                }
            })
            state.recipes = items
        }else{
            state.filteredRecipes = state.recipes
            state.searchState = 1
            items = state.filteredRecipes.filter(item =>{
                if(item.category_id == id){
                    return item;
                }
            })
            state.recipes = items
        }
        
    },
    RECIPE_FILTER_BY_KEY(state,key){
        if(state.searchState == 1){
            var items = state.filteredRecipes.filter(item =>{
                if(item.title.toLowerCase().match(key.toLowerCase())){
                    return item;
                }
            })
            state.recipes = items
        }else{
            state.filteredRecipes = state.recipes
            state.searchState = 1
            items = state.filteredRecipes.filter(item =>{
                if(item.title.toLowerCase().match(key.toLowerCase())){
                    return item;
                }
            })
            state.recipes = items
        }
        
    },
  },

  actions: {
    async ACT_RECIPE_LIST({state, commit}, resInfo){
        state.catId = resInfo.id
       let response = await axios.get(`/recipes?category_id=${resInfo.catId}`)
       if(response.data.success) commit('SET_RECIPE', response.data.data)
    },
    async ACT_RECIPE_CATEGORY_LIST({commit}){
       let response = await axios.get(`/recipe-categories`)
       if(response.data.success) commit('SET_CATEGORIES', response.data.data)
    },
   
    

  },
  modules: {
  }
}

export default recipe