import axios from 'axios'
const contact = {
  namespaced: true,
  state: {
    messageData:{
      name: '',
      email: '',
      phone: '',
      message: ''
    }
  },
  getters: {
    
  },
  mutations: {
    updateName(state, value) {
        state.messageData.name = value;
      },
      updatePhone(state, value) {
        state.messageData.phone = value;
      },
      updateEmail(state, value) {
        state.messageData.email = value;
      },
      updateMessage(state, value) {
        state.messageData.message = value;
      },
  },
  actions: {

    async ACT_SEND_MESSAGE(state, payload){
        return new Promise( (resolve, reject) => {
            axios.post('contact/store', payload)
            .then(data => {
                if(data.data.success){
                    resolve(data.data);
                }else {
                    resolve(data.data);
                }
            })
            .catch(error => {
                reject(error)
            });
        });
    },

      

  },
  modules: {
  }


}

export default contact