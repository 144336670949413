<template>
  <div>
    <HeaderComponent 
      :gbCategories="gbCategories"
      :eCategories="eCategories"
      :recipeCategories="recipeCategories"
    ></HeaderComponent>
    <MiniCart />
<!--      <keep-alive exclude = ['CartPage', 'PlaceOrder']>-->
      <keep-alive>
        <router-view/>
      </keep-alive>
    <FooterComponent></FooterComponent>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import HeaderComponent from './components/includs/HeaderComponent.vue';
  import FooterComponent from './components/includs/FooterComponent.vue';
  // import Header from './components/includs/HeaderComponent';
  import MiniCart from './components/MiniCart'

  // import Footer from './components/includs/FooterComponent';
  export default {
    name: 'app',
    components:{  MiniCart, HeaderComponent, FooterComponent },
    computed: {
      ...mapGetters({
        gbCategories: 'category/GET_GB_CATEGORIES',
        eCategories: 'category/GET_E_CATEGORIES',
        recipeCategories: 'category/GET_RECIPE_CATEGORIES',
      })
    },
    mounted(){
      // this.$store.dispatch('category/ACT_E_CATEGORIES_LIST')
      // this.$store.dispatch('category/ACT_GB_CATEGORIES_LIST')
      // this.$store.dispatch('category/ACT_RECIPE_CATEGORIES_LIST')
    }
  }
</script>
<style>
  .main-menu .router-link-exact-active {
    color: #e54c2a !important;
  }
  .top-menu-item .router-link-exact-active {
    color: #dcd9d9 !important;
  }

  a.dropdown-submenu {
    position: relative;
  }
  .dropdown-submenu:hover .dropdown-menu {
    display: block;
    top: 0;
    position: absolute;
    left: 100%;
    margin: 0;
    padding: 0;
    max-height: 200px;
    overflow: auto;
  }

.dropdown-submenu:hover .dropdown-menu::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
  }

.dropdown-submenu:hover .dropdown-menu::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
  }

.dropdown-submenu:hover .dropdown-menu::-webkit-scrollbar-thumb {
    background-color: #000000;
  }

  ul.dropdown-menu>li {
    list-style: none !important;
    width: 100%;
    border: 0;
  }
  ul.dropdown-menu>li {
    padding: 0;
  }
  .dropdown-menu>li a {
    text-align: center !important;
    font-size: 11px !important;
    text-transform: uppercase !important;
    padding: 7px 7px 3px 10px!important;
  }
  ul.dropdown-menu>li a::before {
    display: none;
  }
  ul.dropdown-menu>li a:hover {
    color: #e54c2a;
  }
</style>
