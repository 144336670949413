<template>
    <div @click="goToCartPage" class="cart">
        <div class="top">
            <h2 class="f-z"><i  class="fa fa-cart-plus text-white"></i></h2>
            <span class="text-white amount">{{ total }} Item<span v-if="total>1">s</span></span> 
        </div>
        <div class="bottom">
            ৳ <span class="count">{{ (cartTotal).toFixed(2) }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MiniCart',
    computed: {
        total: function() {
            return this.$store.state.cart.length;
        },
        cartTotal() {
        var sum = 0;
        for (var i = 0; i < this.$store.state.cart.length; i++) {
            var currentItem = this.$store.state.cart[i];
            if(currentItem.product.is_discount_percentage == 0){
                sum += (currentItem.product.price - currentItem.product.discount)* currentItem.count;
            }
            else{
                sum += (currentItem.product.price - (currentItem.product.price * currentItem.product.discount) / 100)* currentItem.count;
            }
            
        }
        return sum;
        },
    },
    methods: {
        goToCartPage(){
            this.$router.push({name: 'cart.details'})
        }
    }
    
}
</script>

<style scoped>
 .cart{
    text-align: center;
    width: 84px;
    height: 23px;
    position: fixed;
    top: 50%;
    z-index: 999;
    right: 0px;
    opacity: .5;
 }
 .cart:hover{
     opacity: 1;
     cursor: pointer;
 }
 .f-z{
     font-size: 28px;
 }
 .amount{
    font-weight: bold;
    margin-top: -10px;
    display: block;
 }
 .top{
    background: black;
    height: 56px;
    position: relative;
    display: block;
 }
 .bottom{
    color: #150704;
    background: #e54c2a;
    font-weight: bold;
 }
 .fa{
     font-size: 20px;
 }
 @media (min-width: 350px) and (max-width: 769px ){
  .cart{
     width: 50px;
    position: fixed;
   font-size: 10px;
    z-index: 999;
   
  }
  .top{
    height: 40px;
  }
    .f-z{
        font-size: 4px !important;
    }
 }
</style>